/**
 *The CpraUtil class is used to manage cookie preferences for changes between Mailchimp and intuit.
 */

class CpraUtil {
  config = {
    intuitCookie: 'cpra',
    oneTrustCookie: 'OptanonConsent',
    oneTrustBannerCookie: 'OptanonAlertBoxClosed',
  };

  categoryEnabled = '1';
  categoryDisabled = '0';

  oneTrustCategories = {
    essential: '1',
    advertising: '4',
  };

  intuitToOnetrustMap = {
    strictly_necessary_permission: this.oneTrustCategories.essential,
    advertising_permission: this.oneTrustCategories.advertising,
  };

  oneTrustPreferences = {};
  intuitPreferences = {};

  static region = {
    countryCode: 'US',
    regionCode: 'CA',
  };

  constructor() {
    window.mailchimp_cookie_preferences =
      window.mailchimp_cookie_preferences || {};
    window.mailchimp_cookie_preferences.syncPreferences = () => {
      this.syncPreferences();
    };
  }

  /**
   * Checks if the provided country code and the regoin code matches the cpra region identifiers.
   *
   * @param {String} countryCode The code for the country of the current visitor's region
   * @param {String} regionCode The code for visitor's current region
   * @return {Boolean} will return true when the region is considered the cpra region
   */
  static isCPRARegion(countryCode, regionCode) {
    return (
      countryCode === CpraUtil.region.countryCode &&
      regionCode === CpraUtil.region.regionCode
    );
  }

  /**
   * Creates an instance of the cpra util and executes of the methods needed
   * to get cookie values and updating when necessary.
   *
   */
  static initializeCPRA(hostName) {
    const util = new CpraUtil();
    const intuitCookie = CpraUtil.getCookieValue(util.config.intuitCookie);
    const oneTrustCookie = CpraUtil.getCookieValue(util.config.oneTrustCookie);
    const oneTrustAlertBoxCookie = CpraUtil.getCookieValue(
      util.config.oneTrustBannerCookie,
    );
    if (typeof oneTrustCookie !== 'undefined') {
      util.getOneTrustPreferences(oneTrustCookie);
    }

    const hasIntuitCookie = typeof intuitCookie !== 'undefined';

    if (hasIntuitCookie && typeof oneTrustAlertBoxCookie === 'undefined') {
      util.supressBanner(hostName);
    }

    if (hasIntuitCookie) {
      util.getintuitPreferences(intuitCookie);
      util.copyCookiePreferences(intuitCookie, oneTrustCookie, hostName);
    }
  }

  /**
   * Creates a bugsnagClient report if busnag is available on the window object.
   *
   * @param {String} message error message to surface
   * @param {Object} metaData Data related to the error that occured
   */
  reportError(message, metaData) {
    if (
      typeof window.bugsnagClient !== 'undefined' &&
      typeof window.bugsnagClient.notify === 'function'
    ) {
      window.bugsnagClient.notify(message, (bugsnagEvent) => {
        bugsnagEvent.addMetadata('context', metaData);
      });
    }
  }

  /**
   * Returns an object hat extracts the OT categories from the decoded cookie and returns an object
   * using the values a key value pair.
   * values.
   *
   * @param {String} cookieValue one trust cookie value
   * @return {object} object containing key value pairs
   */
  extractActiveGroupsValues(cookieValue) {
    const groups = this.isolateOneTrustGroups(cookieValue);

    return groups.reduce((acc, current) => {
      const categories = current.split(':');
      acc[categories[0]] = categories[1];
      return acc;
    }, {});
  }

  /**
   * Store and return the initial values we receive from the OneTrust cookie
   * values.
   * @param {String} cookieValue one trust cookie value
   * @return {Array} object containing key value pairs
   */
  getOneTrustPreferences(cookieValue) {
    if (Object.keys(this.getOneTrustPreferences).length === 0) {
      this.oneTrustPreferences = this.extractActiveGroupsValues(cookieValue);
    }
    return this.oneTrustPreferences;
  }

  /**
   * Takes in Decoded OneTrust cookie value, and returns an array with the avaialble categories and its
   * values.
   * @param {String} groups one trust cookie value
   * @return {Array} list of categores and their value
   */
  isolateOneTrustGroups(groups) {
    const ioslatedGroups = groups.split('groups=')[1];

    if (typeof ioslatedGroups === 'undefined') {
      return [];
    }

    return ioslatedGroups.split('&')[0].split(',');
  }

  /**
   * Creates a script element and inserts it into the DOM with supplied domainScript.
   * @param {String} url one trust sdk
   * @param {String} domainScript domain script id
   * @return {String} html of script element
   */
  static insertScript(url, domainScript) {
    const newScript = document.createElement('script');
    newScript.src = url;
    if (domainScript) {
      newScript.setAttribute('data-domain-script', domainScript);
    }
    document.body.appendChild(newScript);
    return newScript.outerHTML;
  }

  /**
   * Takes in intuit cookie value, and returns an object mapped to OneTrust category ids
   *
   * @param {String} cookieValue one trust cookie value
   * @return {Object} key value pairs for into to one trust preferencs
   */
  getintuitPreferences(cookieValue) {
    const preferences = cookieValue.split('|');

    this.intuitPreferences[
      this.intuitToOnetrustMap.strictly_necessary_permission
    ] = preferences[0];

    this.intuitPreferences[this.intuitToOnetrustMap.advertising_permission] =
      preferences[1];

    return this.intuitPreferences;
  }

  copyCookiePreferences(intuitCookie, oneTrustCookie, domain) {
    const oneTrustCookieExists = typeof oneTrustCookie !== 'undefined';
    if (typeof intuitCookie === 'undefined') {
      return;
    }
    // if we have the intuit cookie but not the oneturst cookie
    if (!oneTrustCookieExists) {
      const defaultGroups = 'groups=1:1,2:0,3:0,4:0&isGpcEnabled=0';
      this.updatePreferences(intuitCookie, defaultGroups, domain);
    }

    // have intuit cookie and OneTrust cookie but no groups
    if (oneTrustCookieExists && oneTrustCookie.includes('groups') === false) {
      const defaultOTGroups =
        oneTrustCookie +
        '&' +
        decodeURIComponent('groups=1%3A1%2C2%3A0%2C3%3A0%2C4%3A0');
      this.updatePreferences(intuitCookie, defaultOTGroups, domain);
    }

    // if we have both cookies and they are different consent
    if (oneTrustCookieExists && this.isAdvertisingDifferent()) {
      this.updatePreferences(intuitCookie, oneTrustCookie, domain);
    }
  }

  /**
   * Handles copying of prefernces from one cookie to another.
   * @param {String} intuitCookie intuit supplied preferences
   * @param {String} oneTrustCookie one trust supplied preferences
   * @return {String} user's preferences
   */
  updatePreferences(intuitCookie, localCookie, domain) {
    const currentPrefs = this.isolateOneTrustGroups(localCookie);
    intuitCookie.split('|').forEach((preference, index) => {
      switch (index) {
        case 0:
          // essential
          currentPrefs[0] = `${this.oneTrustCategories.essential}:${preference}`;
          break;
        case 1:
          // advertising
          currentPrefs[3] = `${this.oneTrustCategories.advertising}:${preference}`;
          this.oneTrustPreferences[
            this.oneTrustCategories.advertising
          ] = preference;
          break;
        default:
        // ignore non-defined preferences
      }
    });
    const updatedCookieValue = localCookie.replace(
      /(groups=)[^&]+/,
      '$1' + currentPrefs,
    );

    if (domain) {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() + 1);

      this.setCookie(this.config.oneTrustCookie, updatedCookieValue, {
        domain: `.${domain}; Samesite=Lax; secure`,
        expires: currentDate,
      });
    } else {
      this.setCookie(this.config.oneTrustCookie, updatedCookieValue);
    }
    return updatedCookieValue;
  }

  /**
   * Get value of cookie by name.
   * @param {String} name key for cookie
   * @return {String} value of cookie
   */
  static getCookieValue(name) {
    const cookies = `${document.cookie}`;
    const cookieParts = cookies.split(`${name}=`);
    if (cookieParts.length === 2) {
      return decodeURIComponent(
        cookieParts
          .pop()
          .split(';')
          .shift(),
      );
    }

    return undefined;
  }

  /**
   * Encodes special characters for OneTrust related cookies
   * @param {String} key name of cookie
   * @param {String} value data to store
   * @return {String} cookie
   */
  encodeCookie(key, value) {
    const encodedComma = encodeURIComponent(',');
    const encodedColon = encodeURIComponent(':');
    const util = new CpraUtil();
    if (
      key === util.config.oneTrustCookie ||
      key === util.config.oneTrustBannerCookie
    ) {
      value = value.replace(/,/g, encodedComma).replace(/:/g, encodedColon);
    }
    const cookie = key + '=' + value;
    return cookie;
  }

  /**
   * Sets value of cookie with supplied parameters.
   * @param {String} key name of cookie
   * @param {String} value data to store
   * @param {String} path optional path
   * @param {String} expires optional expires
   * @param {String} domain optional domain
   * @return {String} cookie
   */
  setCookie(key, value, { path = '/', expires, domain } = {}) {
    let cookie = this.encodeCookie(key, value);
    if (typeof path === 'string') {
      cookie += `;path=${path}`;
    }

    if (expires !== undefined && typeof expires.getMonth === 'function') {
      cookie += `;expires=${expires}`;
    }

    if (typeof domain === 'string') {
      cookie += `;domain=${domain}`;
    }
    document.cookie = cookie;
    return cookie;
  }

  /**
   * Sets a cookie that the OneTrust library uses to determine that the banner should
   * not be displayed
   *
   * @param {String} domain The hostname that this library is being executed on.
   * @param {String} cookieBannerValue The value that should be used for banner blocking cookie.
   * @param {Function} setCookie the function that can should be called to set a cookie.
   *
   * @return {String} string representing the date that the banner cookie was created
   */
  supressBanner(domain, cookieBannerValue = null, setCookie = null) {
    if (cookieBannerValue === null) {
      cookieBannerValue = new Date().toISOString();
    }

    if (setCookie === null) {
      const currentDate = new Date();
      currentDate.setFullYear(currentDate.getFullYear() + 1);

      this.setCookie(this.config.oneTrustBannerCookie, cookieBannerValue, {
        domain: `${domain}; Samesite=Lax; secure`,
        expires: currentDate,
      });
    } else {
      setCookie();
    }

    return cookieBannerValue;
  }

  getLawConfig(law) {
    try {
      return window.intuit_gdpr.util.getLawConfig(law);
    } catch (error) {
      this.reportError('Unable to get law config', {
        error: error,
        hasGdprUtil: typeof window.intuit_gdpr !== 'undefined',
      });

      return {};
    }
  }

  setUserConsent(endpoints, preferences, law) {
    try {
      window.intuit_gdpr.util.setUserConsent(
        endpoints,
        preferences,
        () => {},
        (failedEndpoints) => {
          this.reportError('Unable to sync consent', {
            failedEndpoints: failedEndpoints,
          });
        },
        law,
      );
    } catch (error) {
      this.reportError('Unable to set user consent', {
        error: error,
        endponts: endpoints,
        preferences: preferences,
        law: law,
        hasGdprUtil: typeof window.intuit_gdpr !== 'undefined',
      });
    }
  }

  /**
   * Gets the Preferences that have been set in Onetrust and maps them to Intuit preferences
   *
   * @param {Object} lawConfig Intuit's cpra congiruation.
   *
   * @return {String} object that maps OneTrust prefs to Intuit Prefs
   */
  mapOneTrustToIntuitPreferences(lawConfig, optanonActiveGroups = null) {
    if (optanonActiveGroups === null) {
      optanonActiveGroups = window.OptanonActiveGroups;
    }

    return lawConfig.checkboxes.reduce((acc, current) => {
      const intuitCategoryId = current.value;
      const oneTrustCategoryId = this.intuitToOnetrustMap[intuitCategoryId];
      if (oneTrustCategoryId === this.oneTrustCategories.essential) {
        acc[current.value] = true;
        return acc;
      }
      acc[current.value] = optanonActiveGroups.includes(oneTrustCategoryId);
      return acc;
    }, {});
  }

  /**
   * Compares the oneTrust preferencs to the intuit preferences. if they are different we
   * should synch
   *
   * @return {Boolean} object that maps OneTrust prefs to Intuit Prefs
   */
  isAdvertisingDifferent() {
    const oneTrustAdvertisingPref = this.oneTrustPreferences[
      this.oneTrustCategories.advertising
    ];
    const intiutAdvertistingPref = this.intuitPreferences[
      this.intuitToOnetrustMap.advertising_permission
    ];
    return oneTrustAdvertisingPref !== intiutAdvertistingPref;
  }

  /**
   * Gets the updated cookie values and determines if the advertising category
   * is different.
   *
   * @return {Boolean} object that maps OneTrust prefs to Intuit Prefs
   */
  shouldSyncPreferences(optanonActiveGroups = null) {
    if (optanonActiveGroups === null) {
      optanonActiveGroups = window.OptanonActiveGroups;
    }

    const intiutAdvertistingPref = this.intuitPreferences[
      this.intuitToOnetrustMap.advertising_permission
    ];

    if (typeof intiutAdvertistingPref === 'undefined') {
      return true;
    }

    const oneTrustAdvertisingIsEnabled = optanonActiveGroups.includes(
      this.oneTrustCategories.advertising,
    );

    if (
      (intiutAdvertistingPref === this.categoryDisabled &&
        oneTrustAdvertisingIsEnabled === true) ||
      (intiutAdvertistingPref === this.categoryEnabled &&
        oneTrustAdvertisingIsEnabled === false)
    ) {
      return true;
    }

    return false;
  }

  syncPreferences() {
    if (this.shouldSyncPreferences() === true) {
      const cpraLawConfig = this.getLawConfig(this.config.intuitCookie);

      if (Object.keys(cpraLawConfig).length === 0) {
        return;
      }
      const consentPreferences = this.mapOneTrustToIntuitPreferences(
        cpraLawConfig,
      );

      this.setUserConsent(
        cpraLawConfig.endpoints,
        consentPreferences,
        this.config.intuitCookie,
      );
    }
  }
}

export default CpraUtil;
