import CpraUtil from './Utils/index';

(() => {
  const cookiePreverencesManagerScript = document.querySelector(
    'script[data-id="cookie-preferences-manager"]',
  );

  const countryCode = cookiePreverencesManagerScript.getAttribute(
    'data-country-code',
  );

  const regionCode = cookiePreverencesManagerScript.getAttribute(
    'data-region-code',
  );

  const domainScript = cookiePreverencesManagerScript.getAttribute(
    'data-domain-script',
  );

  const mcGeoOverride = CpraUtil.getCookieValue('MC_GEO_OVERRIDE');

  if (
    CpraUtil.isCPRARegion(countryCode, regionCode) ||
    mcGeoOverride === 'US|CA'
  ) {
    const hostName = cookiePreverencesManagerScript.getAttribute(
      'data-host-name',
    );
    CpraUtil.initializeCPRA(hostName);
  }

  CpraUtil.insertScript(
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
    domainScript,
  );
})();
